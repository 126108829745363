exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-mdx": () => import("./../../../src/pages/privacy-policy/index.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-index-mdx" */),
  "component---src-pages-tailwind-index-tsx": () => import("./../../../src/pages/tailwind/index.tsx" /* webpackChunkName: "component---src-pages-tailwind-index-tsx" */),
  "component---src-pages-widget-covid-19-index-tsx": () => import("./../../../src/pages/widget-covid-19/index.tsx" /* webpackChunkName: "component---src-pages-widget-covid-19-index-tsx" */),
  "component---src-pages-workshops-reactjs-tsx": () => import("./../../../src/pages/workshops/reactjs.tsx" /* webpackChunkName: "component---src-pages-workshops-reactjs-tsx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-a-comprehensive-guide-to-enabling-windows-subsystem-for-linux-wsl-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/a-comprehensive-guide-to-enabling-windows-subsystem-for-linux-wsl/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-a-comprehensive-guide-to-enabling-windows-subsystem-for-linux-wsl-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-apa-itu-reactjs-belajar-reactjs-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/apa-itu-reactjs-belajar-reactjs/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-apa-itu-reactjs-belajar-reactjs-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-apa-kelebihan-tailwindcss-dibanding-bootstrap-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/apa-kelebihan-tailwindcss-dibanding-bootstrap/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-apa-kelebihan-tailwindcss-dibanding-bootstrap-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-belajar-pemrograman-online-gratis-harvard-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/belajar-pemrograman-online-gratis-harvard/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-belajar-pemrograman-online-gratis-harvard-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-belajar-ui-ux-komunitas-surabayadev-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/belajar-ui-ux-komunitas-surabayadev/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-belajar-ui-ux-komunitas-surabayadev-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-beli-charger-macbook-di-icolor-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/beli-charger-macbook-di-icolor/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-beli-charger-macbook-di-icolor-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-best-tailwind-css-admin-template-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/best-tailwind-css-admin-template/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-best-tailwind-css-admin-template-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-cara-memperbaiki-keyboard-laptop-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/cara-memperbaiki-keyboard-laptop/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-cara-memperbaiki-keyboard-laptop-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-cara-menggunakan-instagram-di-komputer-pc-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/cara-menggunakan-instagram-di-komputer-pc/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-cara-menggunakan-instagram-di-komputer-pc-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-cara-merawat-layar-lcd-laptop-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/cara-merawat-layar-lcd-laptop/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-cara-merawat-layar-lcd-laptop-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-font-ngoding-jetbrains-mono-coding-2020-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/font-ngoding-jetbrains-mono-coding-2020/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-font-ngoding-jetbrains-mono-coding-2020-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-front-end-developer-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/front-end-developer/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-front-end-developer-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-google-v-8-javascript-engine-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/google-v8-javascript-engine/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-google-v-8-javascript-engine-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-how-to-fix-sdk-iphoneos-cannot-be-located-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/how-to-fix-sdk-iphoneos-cannot-be-located/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-how-to-fix-sdk-iphoneos-cannot-be-located-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-html-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/html/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-html-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-javascript-hoisting-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/javascript-hoisting/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-javascript-hoisting-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-javascript-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/javascript/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-javascript-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-melakukan-safari-browser-webkit-testing-di-linux-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/melakukan-safari-browser-webkit-testing-di-linux/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-melakukan-safari-browser-webkit-testing-di-linux-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-membersihkan-mechanical-keyboard-secara-sederhana-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/membersihkan-mechanical-keyboard-secara-sederhana/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-membersihkan-mechanical-keyboard-secara-sederhana-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-menampilkan-pdf-pada-html-tanpa-javascript-1-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/menampilkan-pdf-pada-html-tanpa-javascript-1/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-menampilkan-pdf-pada-html-tanpa-javascript-1-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-mengatasi-wsl-2-error-0-x-8007019-e-pada-windows-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/mengatasi-wsl-2-error-0x8007019e-pada-windows/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-mengatasi-wsl-2-error-0-x-8007019-e-pada-windows-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-menghadiri-event-komunitas-surabayajs-di-dilo-surabaya-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/menghadiri-event-komunitas-surabayajs-di-dilo-surabaya/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-menghadiri-event-komunitas-surabayajs-di-dilo-surabaya-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-merawat-dan-mencegah-staingate-pada-layar-retina-macbook-index-md": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/merawat-dan-mencegah-staingate-pada-layar-retina-macbook/index.md" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-merawat-dan-mencegah-staingate-pada-layar-retina-macbook-index-md" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-pencarian-produk-halal-mui-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/pencarian-produk-halal-mui/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-pencarian-produk-halal-mui-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-purgecss-optimasi-mengurangi-ukuran-css-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/purgecss-optimasi-mengurangi-ukuran-css/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-purgecss-optimasi-mengurangi-ukuran-css-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-reactjs-tutorial-dasar-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/reactjs-tutorial-dasar/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-reactjs-tutorial-dasar-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-review-logitech-g-pro-x-keyboard-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/review-logitech-g-pro-x-keyboard/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-review-logitech-g-pro-x-keyboard-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-surabayadev-meningkatkan-skill-js-bersama-arryangga-dan-antoni-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/surabayadev-meningkatkan-skill-js-bersama-arryangga-dan-antoni/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-surabayadev-meningkatkan-skill-js-bersama-arryangga-dan-antoni-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-tailwind-css-with-cdn-html-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/tailwind-css-with-cdn-html/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-tailwind-css-with-cdn-html-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-tailwind-vs-bootstrap-chooing-right-framework-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/tailwind-vs-bootstrap-chooing-right-framework/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-tailwind-vs-bootstrap-chooing-right-framework-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-tips-agar-baterai-laptop-awet-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/tips-agar-baterai-laptop-awet/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-tips-agar-baterai-laptop-awet-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-top-5-react-chart-libraries-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/top-5-react-chart-libraries/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-top-5-react-chart-libraries-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-widget-kawal-corona-wordpress-blogspot-statistik-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/widget-kawal-corona-wordpress-blogspot-statistik/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-widget-kawal-corona-wordpress-blogspot-statistik-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-winget-windows-package-manager-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/winget-windows-package-manager/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-winget-windows-package-manager-index-mdx" */),
  "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-yaml-tutorial-beginner-index-mdx": () => import("./../../../src/templates/Blog/index.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/yaml-tutorial-beginner/index.mdx" /* webpackChunkName: "component---src-templates-blog-index-tsx-content-file-path-opt-buildhome-repo-content-blog-yaml-tutorial-beginner-index-mdx" */),
  "component---src-templates-categories-index-tsx": () => import("./../../../src/templates/Categories/index.tsx" /* webpackChunkName: "component---src-templates-categories-index-tsx" */),
  "component---src-templates-tags-index-tsx": () => import("./../../../src/templates/Tags/index.tsx" /* webpackChunkName: "component---src-templates-tags-index-tsx" */),
  "component---src-templates-tailwind-demo-index-tsx": () => import("./../../../src/templates/TailwindDemo/index.tsx" /* webpackChunkName: "component---src-templates-tailwind-demo-index-tsx" */)
}

