import React from "react"
import Highlight, {
  defaultProps,
  Language,
  PrismTheme,
} from "prism-react-renderer"
import dracula from "prism-react-renderer/themes/nightOwl"

// import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";

interface ICode {
  codeString: string
  language: Language
  "react-live"?: boolean
}

export const Code: React.FC<ICode> = ({ codeString, language, ...props }) => {
  if (props["react-live"]) {
  }
  // if (props["react-live"]) {
  //   return (
  //     <LiveProvider theme={dracula} code={codeString} noInline={true}>
  //       <LiveEditor />
  //       <LiveError />
  //       <LivePreview />
  //     </LiveProvider>
  //   );
  // } else {
  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={dracula as PrismTheme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
  // }
}

export default Code
